import './App.css';
import img1 from "./cmng.png"
import Hero from './components/Hero';
function App() {
  return (
    <div className="App">
      <header className="App-header">

       <Hero/>
      </header>
    </div>
  );
}

export default App;
