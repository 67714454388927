import {
  Avatar,
  Box,
  Button,
  IconButton,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React from "react";
import img1 from "./1.png";
import dex from "./dex.png";
import tw from "./twitter.svg"
import tg from "./tg.svg"
import dxt from "./dextools.svg"

function Hero() {
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down("lg"));
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        flexDirection: isSmall ? "column-reverse" : "row",
      }}
    >
      <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
        <Typography fontWeight={"bold"} textAlign={"center"} variant="h3">
          BONKAPES
        </Typography>
        <Typography fontWeight={"bold"} textAlign={"center"} variant="h5">
          BUCKLE UP FOR MAXIMUM BONKAGE!
        </Typography>
        <Button size="small" variant="contained" sx={{ backgroundColor: "white",mx:2,p:2,boxShadow:"none",borderRadius:"0px", '&:hover': {backgroundColor:"white",boxShadow:"none"}, '&:active': {backgroundColor:"white"}}}>
          <Typography
            sx={{
              textShadow: "2px 2px 2px #CED0A0",
              fontWeight:theme.typography.fontWeightMedium,
            }}
            variant={!isSmall ? "h3" : "body1"}
            color={"#CED0A0"}
          >
            BONKAPES.COM
          </Typography>2000
        </Button>
        <Box>
            <IconButton target="_blank" href="https://dexscreener.com/ethereum/0x3a5b666d0aa3b9711f2584e593d7d03aee448ba2"> <Avatar sx={{width:"60px",height:"60px"}} src= {dex} ></Avatar> </IconButton>
            <IconButton target="_blank" href="https://twitter.com/realbonkapes"><Avatar sx={{width:"60px",height:"60px"}}  src= {tw} ></Avatar></IconButton>
            <IconButton target="_blank" href="https://t.me/BONKAPESETH"><Avatar sx={{width:"60px",height:"60px"}}  src= {tg} ></Avatar></IconButton>
            <IconButton target="_blank" href="https://www.dextools.io/app/tr/ether/pair-explorer/0x3a5b666d0aa3b9711f2584e593d7d03aee448ba2?t=1715351016953"><Avatar sx={{width:"60px",height:"60px"}}  src= {dxt} ></Avatar></IconButton>

        </Box>
      </Box>
      <img style={{ maxWidth: isSmall ? "300px" : "600px", maxHeight: "600px" }} src={img1} />
    </Box>
  );
}

export default Hero;
